<template>
  <div>
    <audio controls="constrols" src="./success02.mp3" ref="audio" />
    <button @click="startPlay">play</button>
    <p>code: {{ codeRef }}</p>
    <StreamBarcodeReader
      v-if="showReader"
      @decode="onDecode"
      @loaded="onLoaded"
    ></StreamBarcodeReader>
  </div>
</template>

<script setup>
import { ref, watch } from 'vue'
import { StreamBarcodeReader } from 'vue-barcode-reader'

const codeRef = ref('')
const showReader = ref(true)
const audio = ref(null)

const onDecode = (code) => {
  console.log(code)
  codeRef.value = code
  showReader.value = false
  startPlay()
}
const onLoaded = () => {
  console.log('loaded')
}

const startPlay = () => {
  audio.value.currentTime = 0
  audio.value.play()
}
</script>

<style lang="scss" scoped></style>
