import dayjs from 'dayjs'

export default function (app) {
  app.directive('format-time', {
    created(el, bindings) {
      console.log('created', el, bindings)
    },
    mounted(el, bindings) {
      console.log('mounted', el, bindings)
      const textContent = el.textContent
      let timestamp = Number(textContent)
      if (textContent.length === 10) {
        timestamp = timestamp * 1000
      }
      el.textContent = dayjs(timestamp).format('YYYY-MM-DD HH:mm:ss')
    }
  })
}
